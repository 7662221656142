const zlavyimg = [


    {
        id: "1",
        url: require("../img/ZLAVY/esthederm.png"),
        src: require("../img/ZLAVY/esthederm.png"),
        alt: 'Esthederm'
    },

    {
        id: "2",
        url: require("../img/ZLAVY/aderma.jpg"),
        src: require("../img/ZLAVY/aderma.jpg"),
        alt: 'Aderma'
    },

    {
        id: "3",
        url: require("../img/ZLAVY/bioderma.png"),
        src: require("../img/ZLAVY/bioderma.png"),
        alt: 'Bioderma'
    },

    {
        id: "4",
        src: require("../img/ZLAVY/ducray.jpg"),
        url: require("../img/ZLAVY/ducray.jpg"),
        alt: 'Ducray'
    },


    {
        id: "6",
        url: require("../img/ZLAVY/klorane.jpg"),
        src: require("../img/ZLAVY/klorane.jpg"),
        alt: 'Klorane'
    }

]

export default zlavyimg;